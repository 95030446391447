import { writable } from 'svelte/store';

const createStore = () => {
  let eventsParsed = JSON.parse(localStorage.getItem('events')) ?? [];
  eventsParsed = eventsParsed.map(x => {
    return {
      date: new Date(x.date),
      text: x.text,
    };
  });

  console.log(eventsParsed);

  let { subscribe, set, update } = writable(eventsParsed);

  const write = event => {
    update(s => {
      s.push(event);

      localStorage.setItem('events', JSON.stringify(s));
      return s;
    });
  };

  const del = event => {
    update(s => {
      s = s.filter(x => x.text !== event.text && x.date !== event.date);

      localStorage.setItem('events', JSON.stringify(s));
      return s;
    });
  };

  return {
    subscribe,
    write,
    del,
  };
};

export let events = createStore();
