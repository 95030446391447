<script>
  import { events } from './store';
  let text = '';
  let date = '';

  const write = () => {
    events.write({ text, date: new Date(date) });

    text = '';
    date = '';
  };
</script>

<div class="item newdate">
  <input type="text" bind:value={text} placeholder="Event" />
  <input type="date" name="" id="" bind:value={date} />
  <button on:click={write}>Add</button>
</div>

<style>
  .newdate {
    display: flex;
    flex-direction: column;
    text-align: left;

    font-size: 16px;
  }
</style>
