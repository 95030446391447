<script>
  import { onMount } from 'svelte';
  import { events } from './store';
  export let date;
  export let text;

  let days = 0;
  let hours = 0;
  let minutes = 0;
  let seconds = 0;
  let distance = 0;

  const calcCountdown = () => {
    distance = date - Date.now();
    days = Math.floor(distance / (1000 * 60 * 60 * 24));
    hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    seconds = Math.floor((distance % (1000 * 60)) / 1000);
  };

  let interval = setInterval(() => {
    calcCountdown();
  }, 1000);

  let del = () => {
    events.del({ date, text });
  };

  let observer = new IntersectionObserver(cb => {
    if (delElement) {
      console.log('here');
    }
  });

  onMount(() => {
    calcCountdown();
    observer.observe(textElement);
  });

  let textElement;
  let delElement;
</script>

<div class="item">
  <div class="element">
    <div class="description" bind:this={textElement}>{text}</div>
    <div class="wrapper">
      <div class="number">{days}</div>
      <div class="number">{hours}</div>
      <div class="number">{minutes}</div>
      <div class="number">{seconds}</div>
      <div class="text">Tage</div>
      <div class="text">Stunden</div>
      <div class="text">Minuten</div>
      <div class="text">Sekunden</div>
    </div>
    <div class="del" on:click={del} bind:this={delElement}>x</div>
  </div>
</div>

<style>
  .element {
  }
  .del {
    position: absolute;
    right: 20px;
    bottom: 10px;
    cursor: pointer;
    align-self: end;
  }
  .description {
    text-align: center;
    font-size: 48px;

    transform: translate(0, -15vh);
  }
  .wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: auto auto;
    justify-content: space-evenly;
    align-content: center;

    transform: translate(0, -15vh);
  }
  .number {
    font-size: 24pxpx;
    text-align: center;
    font-weight: bold;
  }
  .text {
    font-size: 24px;
    text-align: center;
  }
</style>
