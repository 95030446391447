<script>
  import './styles.css';
  import Countdown from './Countdown.svelte';
  import NewDate from './NewDate.svelte';
  import { events } from './store';
</script>

<div class="container">
  {#each $events as event}
    <Countdown date={event.date} text={event.text} />
  {/each}
  <NewDate />
</div>

<style>
  .container {
    scroll-snap-type: y mandatory;
    overflow: auto;
    max-height: 100vh;
  }
</style>
